import React from 'react'
import { graphql } from "gatsby"


import Header from '../components/header'
import Layout from '../components/layout'
import SEO from '../components/seo'

import "../styles/main.scss"

const AboutPage = () => {

  return (
    <Layout>
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`]}
      />
      <Header text={"We are a team of problem solvers, committed to delivering the most innovative and creative solutions for our partners"}/>
      <div className="about-img-container">
      <img src={"/lisbon.jpg"} alt="Lisbon"/>
      </div>
      <div className="container">
        <h2>Our Vision</h2>
        <p className="text-center">Lead innovation for tech hungry companies.</p>
        <h2>Our Mission</h2>
        <p className="text-center">Drive top organizations with high-quality digital products and services powered by state-of-the-art technology.</p>
        <h2>Where We Are</h2>
        <p className="text-center">We are based in Lisbon city center.</p>
        <h5>Come and visit us at</h5>
        <div>Av. Eng. Arantes e Oliveira N3 R/C</div>
        <div>1900-221 Lisboa</div>
        <div>Portugal</div>
        <h5>Or you can contact us</h5>
        <div>info@forcera.pt</div>
        <div>(+351) 21 139 7376</div>

      </div>
    </Layout>
  )
}

export default AboutPage;

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`      